<template>
  <v-container fluid style="height: 100%; display: flex; flex-direction: column">
    <page-title title="Inventory" subtitle="Browse and Manage your Equipment!" :badge="total" />
    <v-row class="mt-0 mb-1" justify="space-between">
      <v-col md="3" sm="12" cols="12" style="gap: 1rem" class="d-flex flex-wrap align-center">
        <div>
          <v-btn color="info" @click="add"><i class="fal fa-plus mr-2"></i>Add Equipment</v-btn>
        </div>
      </v-col>
      <v-col
        md="9"
        sm="12"
        cols="12"
        class="d-flex flex-row align-center justify-end flex-wrap"
        style="gap: 0.5rem"
      >
        <filter-manager
          ref="filterManager"
          v-model="selectedFilters"
          :options.sync="options"
        ></filter-manager>
        <v-sheet height="28" width="1" color="blue-grey lighten-4" class="mx-1"></v-sheet>
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-badge
              overlap
              color="red"
              bordered
              left
              :value="changes.length"
              :content="changes.length"
            >
              <v-btn
                :disabled="!changes.length"
                :loading="saveChangesLoading"
                :style="{ height: '32px !important' }"
                color="white"
                v-bind="attrs"
                v-on="on"
              >
                <i class="far fa-ellipsis-v"></i>
              </v-btn>
            </v-badge>
          </template>
          <v-list>
            <v-list-item @click="saveChanges">
              <v-list-item-title><i class="fal fa-save mr-2"></i> Save Changes</v-list-item-title>
            </v-list-item>
            <v-list-item @click="discardAll">
              <v-list-item-title
                ><i class="fal fa-undo mr-2"></i> Discard Changes</v-list-item-title
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-title
                ><i class="fal fa-pen-square mr-2"></i> Show changes Only</v-list-item-title
              >
              <v-list-item-action>
                <v-switch v-model="showChangesOnly" color="primary"> </v-switch>
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-if="errorsKey.length">
              <v-list-item-title
                ><i class="fal fa-pen-square mr-2"></i> Show errors Only</v-list-item-title
              >
              <v-list-item-action>
                <v-switch v-model="showErrorsOnly" color="primary"> </v-switch>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
        <columns-visibility-control
          :withColumnsOrder="true"
          v-model="headers"
          :storageKey="storageKey"
          :defaults="defaultHeaders"
        />
        <v-text-field
          v-model="options.search"
          label="Search"
          ref="mainSearch"
          class="table-search-field"
          @focus="mainSearchInFocus = true"
          @blur="mainSearchInFocus = false"
          :style="{
            'max-width':
              mainSearchInFocus || (options.search != '' && options.search != null)
                ? '200px'
                : '110px',
          }"
          dense
          solo
          clearable
          hide-details
          prepend-inner-icon="far fa-search"
          :loading="loadingStates.table"
        >
        </v-text-field>
        <refresh :loading="loadingStates.table" @refresh="getDataDebounced()"></refresh>
      </v-col>
    </v-row>
    <v-data-table
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      dense
      :headers="tableHeader"
      :items="entities"
      :options.sync="options"
      :server-items-length="total"
      :loading="loadingStates.table"
      class="elevation-2 inventory-table table-sticky-header-exclude-footer"
      id="inventoryTable"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:progress>
        <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
      </template>
      <template
        v-for="elm in textFieldHeadersGroup"
        v-slot:[`item.${elm.value}`]="{ item, header, index }"
      >
        <v-text-field-alt
          :key="elm.value + index"
          @blur="pushChanges(item, header, elm.value)"
          v-model="item[elm.value]"
          v-if="header.editable && selectedIndex == index"
          hide-details="auto"
          dense
          class="my-2"
          autofocus
          :rules="elm.validation()"
          :error-messages="
            showChangesOnly &&
            errorsKey.includes(`Equipments[${index}].${elm.serverKey || elm.value}`)
              ? errors[`Equipments[${index}].${elm.serverKey || elm.value}`]
              : []
          "
        ></v-text-field-alt>
        <div
          :key="elm.value + index"
          @click="cloneSelectedItem(item, header, index)"
          v-else-if="
            showChangesOnly &&
            errorsKey.includes(`Equipments[${index}].${elm.serverKey || elm.value}`)
          "
        >
          <div class="font-weight-bold">{{ item[elm.value] }}</div>
          <div
            class="error--text caption"
            v-for="error in errors[`Equipments[${index}].${elm.serverKey || elm.value}`]"
            :key="error[0]"
          >
            {{ error }}
          </div>
        </div>
        <div
          :key="elm.value + index"
          @click="cloneSelectedItem(item, header, index)"
          v-else
          :class="'equipment-' + elm.value"
          class="font-weight-bold fullSize"
        >
          {{ item[elm.value] }}
        </div>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <v-tooltip right z-index="999" nudge-right="-4px">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <div class="equipment-img">
                <div
                  style="height: 100%; width: 100%"
                  v-viewer
                  @click.stop
                  v-if="item.imageUrl != null && item.imageUrl != ''"
                >
                  <img :key="item.id + '_img'" :src="item.imageUrl" height="100%" width="100%" />
                </div>
                <i v-else-if="item.category != null" :class="'fad ' + item.category.icon"></i>
                <i v-else :class="'fad fa-plug'"></i>
              </div>
            </div>
          </template>
          <span>
            <v-img
              v-if="(item.imageUrl != null) & (item.imageUrl != '')"
              class="img"
              :src="item.imageUrl"
              width="250px"
              height="250px"
              contain
            ></v-img>
            <i
              v-else-if="item.category != null"
              :class="'fad ' + item.category.icon"
              style="font-size: 32px; padding: 2rem"
            ></i>
            <i v-else :class="'fad fa-plug'" style="font-size: 32px; padding: 2rem"></i>
          </span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.category`]="{ item, header, index }">
        <v-autocomplete-alt
          auto-select-first
          @blur="pushChanges(item, header, 'category')"
          v-model="item.category"
          v-if="header.editable && selectedIndex == index"
          hide-details
          dense
          class="my-2"
          autofocus
          id="equipmentCategory"
          ref="equipmentCategory"
          placeholder="Equipment Category"
          :items="categories"
          :filter="categoriesFilter"
          filled
          return-object
          item-text="id"
          item-value="name"
        >
          <template v-slot:selection="data">
            <v-row align-content="center" justify="start" no-gutters style="flex: none">
              <v-col sm="auto" class="d-flex align-center mr-2">
                <i :class="'equipment-icon fad ' + data.item.icon"></i>
              </v-col>
              <v-col class="d-flex align-center col-auto">
                <div class="equipment-prop d-flex align-center">
                  {{ data.item.name }}
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item="data">
            <v-row align-content="center" justify="start" no-gutters style="flex: none">
              <v-col sm="auto" class="d-flex align-center mr-2">
                <i :class="'equipment-icon fad ' + data.item.icon"></i>
              </v-col>
              <v-col class="d-flex align-center col-auto">
                <div class="equipment-prop d-flex align-center">
                  {{ data.item.name }}
                </div>
              </v-col>
            </v-row>
          </template>
        </v-autocomplete-alt>
        <v-row no-gutters @click="cloneSelectedItem(item, header, index)" v-else>
          <v-col sm="auto" class="d-flex justify-content-start align-items-center">
            <i v-if="item.category != null" :class="'equipment-icon fad ' + item.category.icon"></i>
            <i v-else :class="'equipment-icon fad fa-plug'"></i>
          </v-col>
          <v-col class="d-flex align-center">
            <span class="category-name" v-if="item.category">{{ item.category.name }}</span>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.provider`]="{ item, header, index }">
        <v-autocomplete-alt
          auto-select-first
          @blur="pushChanges(item, header, 'provider')"
          v-model="item.provider"
          v-if="header.editable && selectedIndex == index"
          hide-details
          dense
          class="my-2"
          autofocus
          id="equipmentProvider"
          placeholder="Provider"
          :items="providers"
          :filter="providersFilter"
          filled
          return-object
          item-text="name"
          item-value="id"
        >
          <template v-slot:selection="data">
            <div style="font-size: 14px" class="d-block">
              {{ data.item.name }}
            </div>
          </template>
          <template v-slot:item="data">
            <div style="font-size: 14px">{{ data.item.name }}</div>
            <p
              class="ma-0 ml-2 text--secondary"
              v-if="data.item.description != null && data.item.description != ''"
            >
              {{ data.item.description }}
            </p>
          </template>
        </v-autocomplete-alt>
        <div
          @click="cloneSelectedItem(item, header, index)"
          v-else
          class="equipment-provider font-weight-bold fullSize"
        >
          <span v-if="item.provider">{{ item.provider.name }}</span>
        </div>
      </template>
      <template v-slot:[`item.description`]="{ item, header, index }">
        <v-textarea-alt
          @blur="pushChanges(item, header, 'description')"
          v-model="item.description"
          v-if="header.editable && selectedIndex == index"
          hide-details
          dense
          class="my-2"
          autofocus
          :rows="1"
          auto-grow
        ></v-textarea-alt>
        <div class="fullSize" @click="cloneSelectedItem(item, header, index)" v-else>
          {{ item.description }}
        </div>
      </template>
      <template v-slot:[`item.installer`]="{ item, header, index }">
        <v-autocomplete-alt
          auto-select-first
          @blur="pushChanges(item, header, 'installer')"
          v-model="item.installer"
          v-if="header.editable && selectedIndex == index"
          hide-details
          dense
          class="my-2"
          id="equipmentInstaller"
          placeholder="Installer"
          :items="installers"
          :filter="installersFilter"
          filled
          return-object
          item-text="name"
          item-value="id"
        >
          <template v-slot:selection="data">
            <div style="font-size: 14px" class="d-block">
              {{ data.item.name }}
            </div>
          </template>
          <template v-slot:item="data">
            <div style="font-size: 14px">{{ data.item.name }}</div>
            <p
              class="ma-0 ml-2 text--secondary"
              v-if="data.item.description != null && data.item.description != ''"
            >
              {{ data.item.description }}
            </p>
          </template>
        </v-autocomplete-alt>
        <div
          @click="cloneSelectedItem(item, header, index)"
          v-else
          class="equipment-provider font-weight-bold fullSize"
        >
          <span v-if="item.installer">{{ item.installer.name }}</span>
        </div>
      </template>
      <template v-slot:[`item.equipmentHashtags`]="{ item, header, index }">
        <v-combobox
          @blur="pushChanges(item, header, 'equipmentHashtags')"
          v-model="item.equipmentHashtags"
          v-if="header.editable && selectedIndex == index"
          hide-details
          dense
          id="equipmentHashtags"
          :persistent-placeholder="true"
          placeholder="Equipment Hashtags"
          append-icon="fas fa-caret-down"
          deletable-chips
          class="my-2"
          :items="equipmentHashtags"
          return-object
          no-filter
          item-text="name"
          item-value="id"
          multiple
          chips
          filled
          solo
          flat
          hide-selected
        >
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip v-bind="attrs" color="secondary" :input-value="selected" label small>
              <span>
                {{ item.name || item }}
              </span>
              <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
            </v-chip>
          </template>
        </v-combobox>

        <div
          @click="cloneSelectedItem(item, header, index)"
          v-else
          class="equipment-provider font-weight-bold fullSize"
          style="gap: 0.5rem"
        >
          <v-chip
            color="secondary"
            v-for="hashtag in item.equipmentHashtags"
            :key="`hashtag-${hashtag.id}`"
            label
            small
          >
            <span>
              {{ hashtag.name || hashtag }}
            </span>
          </v-chip>
        </div>
      </template>
      <template v-slot:[`item.url`]="{ item, header, index }">
        <v-text-field-alt
          @blur="pushChanges(item, header, 'url')"
          v-model="item.url"
          v-if="header.editable && selectedIndex == index"
          hide-details
          dense
          class="my-2"
          autofocus
        ></v-text-field-alt>
        <v-tooltip v-else top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="cloneSelectedItem(item, header, index)"
              class="equipment-prop font-weight-bold fullSize"
              :color="item.url ? 'primary' : 'grey'"
            >
              <i class="fal fa-external-link fa-2xs"> </i>
            </v-btn>
          </template>
          <span>{{ item.url || "please click to edit the url" }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.availability`]="{ item, header, index }">
        <v-select-alt
          id="equipmentAvailability"
          placeholder="availability"
          :items="EquipmentAvailability | EnumToList"
          @blur="pushChanges(item, header, 'availability')"
          v-model="item.availability"
          v-if="header.editable && selectedIndex == index"
          hide-details
          dense
          class="my-2"
          autofocus
        >
        </v-select-alt>

        <div
          @click="cloneSelectedItem(item, header, index)"
          v-else
          class="equipment-prop font-weight-bold fullSize"
        >
          {{ getKeyByValue(EquipmentAvailability, item.availability) }}
        </div>
      </template>
      <template v-slot:[`item.isRackMountable`]="{ item, header, index }">
        <v-switch
          color="info"
          v-model="item.isRackMountable"
          @change="pushChanges(item, header, 'isRackMountable')"
          v-if="header.editable && selectedIndex == index"
          hide-details
          dense
          class="my-2"
        ></v-switch>

        <div
          @click="cloneSelectedItem(item, header, index)"
          v-else
          class="equipment-prop font-weight-bold fullSize"
        >
          <v-chip>{{ item.isRackMountable ? "Yes" : "No" }}</v-chip>
        </div>
      </template>
      <template v-slot:[`item.rackUnits`]="{ item, header, index }">
        <div v-if="item.isRackMountable">
          <v-text-field-alt
            @blur="pushChanges(item, header, 'rackUnits')"
            v-model="item.rackUnits"
            v-if="header.editable && selectedIndex == index"
            hide-details
            dense
            class="my-2"
            autofocus
          ></v-text-field-alt>
          <div
            @click="cloneSelectedItem(item, header, index)"
            v-else
            class="equipment-prop font-weight-bold fullSize"
          >
            {{ item.rackUnits }}
          </div>
        </div>
      </template>
      <template v-slot:[`item.powerConsumption`]="{ item, header, index }">
        <v-text-field-alt
          @blur="pushChanges(item, header, 'powerConsumption')"
          v-model="item.powerConsumption"
          v-if="header.editable && selectedIndex == index"
          hide-details
          dense
          class="my-2"
          autofocus
          :rules="[allRules.requiredNumber]"
          type="number"
          hide-spin-buttons
          @keyup="item.powerAutoCalculate && refreshHeatAndHvac(item)"
        ></v-text-field-alt>
        <div
          @click="cloneSelectedItem(item, header, index)"
          v-else
          class="equipment-prop font-weight-bold fullSize"
        >
          {{ item.powerConsumption }}
        </div>
      </template>
      <template v-slot:[`item.powerAutoCalculate`]="{ item, header, index }">
        <v-btn-toggle
          mandatory
          color="info"
          @change="
            cloneSelectedItem(item, header, index);
            pushChanges(item, header, 'powerAutoCalculate');
            refreshHeatAndHvac(item);
          "
          v-model="item.powerAutoCalculate"
          dense
        >
          <v-btn :value="false" small>
            <!-- <v-icon left> fal fa-lock-open-alt </v-icon> -->
            <i class="fad fa-lock-open-alt mr-2 secondary--text"></i>
            <span>Manual</span>
          </v-btn>
          <v-btn :value="true" small>
            <!-- <v-icon left> fal fa-lock-alt </v-icon> -->
            <i class="fad fa-lock-alt mr-2 secondary--text"></i>
            <span>Auto Derived</span>
          </v-btn>
        </v-btn-toggle>
      </template>
      <template v-slot:[`item.updateDate`]="{ item }">
        <dater :date="item.updateDate" dateonly></dater>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex align-center white" style="position: sticky; right: 0">
          <v-btn
            icon
            v-if="checkIfItemExistInArray(item, 'id', changes)[1]"
            @click="discardChange(item)"
            ><i class="fal fa-undo"></i
          ></v-btn>
          <v-spacer></v-spacer>
          <v-menu dense offset-x left>
            <template v-slot:activator="{ attrs, on }">
              <v-btn small icon elevation="0" v-bind="attrs" v-on="on">
                <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <v-list class="more-options-menu">
              <v-list-item @click.stop="displayStats(item)" title="Clone Template">
                <v-list-item-icon class="mr-2 justify-center">
                  <v-icon small>fal fa-analytics</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Stats</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click.stop="cloneEquipment(item.id)">
                <v-list-item-icon class="mr-2 justify-center">
                  <v-icon small>fal fa-clone</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Clone</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click.stop="openDeleteReplaceDialog(item)">
                <v-list-item-icon class="mr-2 justify-center">
                  <v-icon small>fal fa-trash-undo purple--text</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="purple--text">Delete & Replace</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click.stop="del(item)">
                <v-list-item-icon class="mr-2 justify-center">
                  <v-icon small>fal fa-trash-alt red--text</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="red--text">Delete</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template v-slot:no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>
    <replace-equipment ref="replaceEquipmentComp" @Success="replaceEquipmentSucceeded()">
    </replace-equipment>
    <edit-equipment ref="editEquipment"></edit-equipment>
    <equipment-stats ref="statsEquipment"> </equipment-stats>
  </v-container>
</template>

<script>
import ApiService from "../../Equipments/services/equipments-service";
import inventoryApi from "../services/inventory-service";
import categoriesAPI from "../../Equipments/services/categories-service";
import providersAPI from "../../Equipments/services/providers-service";
import installersAPI from "../../Equipments/services/installers-service";
import hashtagsAPI from "../../Equipments/services/hashtags-service";
import ReplaceEquipment from "../../Equipments/components/ReplaceEquipment.vue";
import EquipmentStats from "../../Equipments/components/EquipmentStats.vue";
import inventoryHeader from "../config/tables/inventory.header";
import FilterManager from "../../Shared/components/FilterManager/FilterManager.vue";
import {
  UserFilterSettings,
  ManufacturerFilterSettings,
  ProductFamilyFilterSettings,
  EquipmentCategoryFilterSettings,
  InstallerFilterSettings,
  ProviderFilterSettings,
} from "../../Shared/components/FilterManager/FilterSettings";

export default {
  components: { ReplaceEquipment, EquipmentStats, FilterManager },
  data() {
    return {
      storageKey: "Equipments",
      openEquipmentInEditMode: false,
      paramId: null,
      entities: [],
      originals: [],
      categories: [],
      providers: [],
      installers: [],
      equipmentHashtags: [],
      selectedFilters: [],
      selected: {},
      total: 0,
      inventoryTimerId: null,
      mainSearchInFocus: false,
      options: {
        id: null,
        search: null,
        sortBy: ["updateDate"],
        sortDesc: [true],
        createdByIds: [],
        updatedByIds: [],
        categoriesId: [],
        providerIds: [],
        installerIds: [],
      },
      storedSearch: null,
      loadingStates: {
        table: false,
      },
      modals: {
        replaceEquipment: {
          active: false,
          sourceEquipment: {},
        },
      },
      EquipmentAvailability: Object.freeze({
        Available: 1,
        Presale: 2,
        Discontinued: 3,
      }),
      headers: inventoryHeader,
      defaultHeaders: [],
      changes: [],
      errors: {},
      selectedItem: null,
      selectedIndex: null,
      showChangesOnly: false,
      showErrorsOnly: false,
      saveChangesLoading: false,
    };
  },
  computed: {
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
    errorsKey() {
      return Object.keys(this.errors);
    },
    textFieldHeadersGroup() {
      return this.headers.filter((elm) => elm.type == "textfield");
    },
  },
  created() {
    this.defaultHeaders = this.headers;
    if (this.$route.query.q) this.options.search = this.$route.query.q;
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    this.initFilterManagerSettings();
    document.querySelector("main.v-main").classList.add("sticky-main-fix");
    this.getCategories();
    this.getProviders();
    this.getInstallers();
    this.getHashtags();
    //this.$refs.mainSearch.focus();
    this.checkRouteQuery();
    this.checkForSingleEquipmentRoute(this.$route);
    this.$moveable(document.querySelector("#inventoryTable .v-data-table__wrapper"));
  },
  beforeDestroy() {
    document.querySelector("main.v-main").classList.remove("sticky-main-fix");
  },
  methods: {
    initFilterManagerSettings() {
      var createdByFilterSettings = new UserFilterSettings();
      createdByFilterSettings.title = "Created By";
      createdByFilterSettings.model = "createdByIds";
      createdByFilterSettings.ref = "createdByFilter";

      var updatedByFilterSettings = new UserFilterSettings();
      updatedByFilterSettings.title = "Updated By";
      updatedByFilterSettings.model = "updatedByIds";
      updatedByFilterSettings.ref = "updatedByFilter";

      var manufacturerFilterSettings = new ManufacturerFilterSettings();

      var productFamilyFilterSettings = new ProductFamilyFilterSettings();

      var equipmentCategoryFilterSettings = new EquipmentCategoryFilterSettings();

      var installerFilterSettings = new InstallerFilterSettings();

      var providerFilterSettings = new ProviderFilterSettings();

      this.selectedFilters = [
        createdByFilterSettings,
        updatedByFilterSettings,
        manufacturerFilterSettings,
        productFamilyFilterSettings,
        equipmentCategoryFilterSettings,
        installerFilterSettings,
        providerFilterSettings,
      ];
    },
    displayStats(item) {
      setTimeout(() => {
        this.$refs.statsEquipment.open(item);
      });
    },
    cloneSelectedItem(item, header, index) {
      this.selectedItem = this.cloneDeep(item);
      this.selectedIndex = index;
      this.toggleEditable(header, true);
    },
    toggleEditable(header, value) {
      this.headers.forEach((elm) => {
        if (elm.value == header.value) {
          elm.editable = value;
        } else {
          elm.editable = false;
        }
      });
    },
    pushChanges(item, header, key) {
      if (this.itemNotEqualOriginal(item, key)) {
        const [index, exist] = this.checkIfItemExistInArray(item, "id", this.changes);
        exist ? this.changes.splice(index, 1, item) : this.changes.push(item);
      }
      this.toggleEditable(header, false);
      this.selectedItem = null;
      this.selectedIndex = null;
    },
    itemNotEqualOriginal(item, key) {
      return !this.isEqual(item[key], this.selectedItem[key]);
    },
    checkIfItemExistInArray(item, key, arr) {
      const index = this.getIndexOfItemInArray(arr, item, key);
      return [index, index !== -1];
    },
    getIndexOfItemInArray(array, item, key) {
      return array.findIndex((elm) => elm[key] == item[key]);
    },
    discardChange(item) {
      const originalIndex = this.getIndexOfItemInArray(this.originals, item, "id");
      const changeIndex = this.getIndexOfItemInArray(this.changes, item, "id");
      if (originalIndex != -1) {
        this.updateArr(this.entities, this.cloneDeep(this.originals[originalIndex]));
      }
      if (changeIndex != -1) {
        this.changes.splice(changeIndex, 1);
      }
    },
    discardAll() {
      this.changes = [];
      this.entities = this.cloneDeep(this.originals);
    },
    checkRouteQuery(evt) {
      // this.$log("checkRoute", this.$route.query.q, evt);
      if (this.$route.query.q) this.options.search = this.$route.query.q;
      else this.options.search = null;
    },
    updateRouterLink() {
      if (this.$route.query.q == this.options.search) return;
      if (this.options.search != null && this.options.search != "") {
        this.$router.push({
          path: "/inventory",
          query: { q: this.options.search },
          replace: true,
        });
      } else this.$router.push({ path: "/inventory", replace: true });
    },
    getData() {
      this.$backToTop(0, document.querySelector(".inventory-table .v-data-table__wrapper"));
      let optionsToSend = this.cloneDeep(this.options);
      if (this.storedSearch != optionsToSend.search) optionsToSend.page = 0;
      // this.$log("######################## GET DATA ########################");
      // this.$log("optionsToSend:", optionsToSend);
      this.storedSearch = optionsToSend.search;
      this.loadingStates.table = true;
      ApiService.query(this.$clean(optionsToSend, true))
        .then((resp) => {
          this.originals = this.cloneDeep(resp.data.items);
          this.entities = this.getEntities(resp.data.items);

          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    getEntities(items) {
      this.changes.forEach((elm) => {
        const [index, exist] = this.checkIfItemExistInArray(elm, "id", items);
        if (exist) items.splice(index, 1, elm);
      });
      return items;
    },
    del(item) {
      const [_, exist] = this.checkIfItemExistInArray(item, "id", this.changes);
      let message = "Are you sure you want to delete this equipment?";
      if (exist) message = "You make a changes on this equipment. " + message;
      this.$dialog
        .warning({
          text: message,
          title: `Delete Equipment?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                if (exist) {
                  this.discardChange(item);
                }
                return ApiService.delete(item.id)
                  .then((resp) => {
                    const index = this.entities.indexOf(
                      this.entities.filter((e) => e.id == item.id)[0]
                    );
                    if (index > -1) {
                      this.entities.splice(index, 1);
                    }
                    this.$dialog.notify.success("Item deleted successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("an error occurred during deleting the item", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    cloneEquipment(id) {
      this.$dialog
        .info({
          text: `Are you sure you want to clone this equipment?`,
          title: `Clone Equipment?`,
          color: "info",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "info",
              handle: () => {
                return ApiService.clone(id)
                  .then((resp) => {
                    const id = resp.data.id;
                    this.options.search = id;
                    this.$dialog.notify.success("Equipment cloned successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error(
                      "Oops! an error occurred during cloning this equipment!",
                      {
                        position: "top-right",
                        timeout: 3000,
                      }
                    );
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    getDataDebounced() {
      if (this.inventoryTimerId == null) {
        this.inventoryTimerId = -1;
        this.updateRouterLink();
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.inventoryTimerId);

      // delay new call 400ms
      this.inventoryTimerId = setTimeout(() => {
        this.updateRouterLink();
        this.getData();
      }, 400);
    },
    openDeleteReplaceDialog(equipment) {
      this.modals.replaceEquipment.sourceEquipment = equipment;
      this.$refs.replaceEquipmentComp.open(equipment);
    },
    replaceEquipmentSucceeded() {
      this.getData();
    },
    add() {
      this.selected = {};
      this.$refs.editEquipment.open(null);
    },
    openEquipment(id) {
      // this.$log("openEquipment =>>>", id, this.openEquipmentInEditMode);
      setTimeout(() => {
        this.$refs.editEquipment.open(id, this.openEquipmentInEditMode);
        this.openEquipmentInEditMode = false;
      }, 50);
    },
    checkForSingleEquipmentRoute(route) {
      // this.$log("checkForSingleEquipmentRoute", route);
      if (route.params && route.params.id) {
        this.paramId = Number.parseInt(route.params.id);
        this.openEquipment(this.paramId);
      } else this.paramId = null;
    },
    getCategories() {
      categoriesAPI.get().then((resp) => (this.categories = resp.data.items));
    },
    getProviders() {
      providersAPI.typeHead(null, true).then((resp) => (this.providers = resp.data));
    },
    getInstallers() {
      installersAPI.typeHead(null, true).then((resp) => (this.installers = resp.data));
    },
    getHashtags() {
      hashtagsAPI.typeHead("", true).then((resp) => (this.equipmentHashtags = resp.data));
    },
    categoriesFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
    providersFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      const textTwo = (item.description || "").toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    installersFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      const textTwo = (item.description || "").toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    getKeyByValue(obj, value) {
      return Object.keys(obj).find((key) => obj[key] === value);
    },
    refreshHeatAndHvac(item) {
      if (item == null || item.powerAutoCalculate != true || !this.isNum(item.powerConsumption))
        return;

      //Heat Load = POWER CONSUMPTION * 3.412
      //HVAC Tonnage = (POWER CONSUMPTION * 3.412) / 12000
      let pc = parseFloat(item.powerConsumption);
      this.$log("POWER CONSUMPTION:", item.powerConsumption, pc);
      item.heatload = (pc * 3.412).toFixed(2);
      item.hvacTonnage = ((pc * 3.412) / 12000).toFixed(5);
    },
    isNum(value) {
      // return /^[0-9]+.[0-9]+$/.test(value);
      return /^[0-9]+.[0-9]+|[0-9]+$/.test(value);
    },
    saveChanges() {
      this.errors = {};
      this.saveChangesLoading = true;
      var toSend = this.cloneDeep(this.changes);
      toSend.forEach((change) => {
        if (change.equipmentHashtags != null && change.equipmentHashtags.length > 0) {
          change.newHashtags = change.equipmentHashtags.filter((hashtag) => hashtag.id == null);
          change.equipmentHashtags = change.equipmentHashtags.filter(
            (hashtag) => hashtag.id != null
          );
          change.equipmentHashtags = change.equipmentHashtags.map((hashtag) => hashtag.id);
        }
        if (change.category != null) {
          change.categoryId = change.category.id;
        }
      });
      this.$log("toSend", toSend);
      inventoryApi
        .update(toSend)
        .then((resp) => {
          this.saveChangesLoading = false;
          this.$dialog.notify.success("changes updated successfully", {
            position: "top-right",
            timeout: 3000,
          });
          this.originals = this.cloneDeep(this.entities);
          this.discardAll();
        })
        .catch((err) => {
          this.saveChangesLoading = false;
          if (err.response.data?.errorType == "Validation") {
            this.errors = err.response.data.errors;
            this.showChangesOnly = true;
          }
          this.$handleError(err);
        });
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getDataDebounced();
      },
      deep: true,
    },
    $route: {
      handler(newRoute, oldRoute) {
        this.checkRouteQuery();
        // this.$log("EQUIPMENTS - oldRoute", oldRoute, "newRoute", newRoute);

        //from equipment list view TO => single equipment view,
        if (oldRoute.name == "inventory" && newRoute.name == "single-inventory") {
          this.openEquipment(newRoute.params.id);
          return;
        }

        //from single equipment view TO => single equipment view,
        if (oldRoute.name == "single-inventory" && newRoute.name == "single-inventory") {
          this.openEquipment(newRoute.params.id);
          return;
        }
      },
      deep: true,
    },
    showChangesOnly(val) {
      if (val) {
        this.entities = this.changes;
      } else {
        this.entities = this.getEntities(this.cloneDeep(this.originals));
      }
    },
    showErrorsOnly(val) {
      if (val) {
        const keys = [
          ...new Set(
            this.errorsKey
              .map((key) => parseInt(key.split(".")[0].replace(/[^\d.]/g, "")))
              .sort((a, b) => a - b)
          ),
        ].filter((elm) => !isNaN(elm));
        this.$log(keys);
        this.entities = this.changes.filter((_, k) => keys.includes(k));
      } else {
        if (this.showChangesOnly) {
          this.entities = this.changes;
        } else {
          this.entities = this.getEntities(this.cloneDeep(this.originals));
        }
      }
    },
    changes(val) {
      if (!val.length) {
        this.showChangesOnly = false;
      }
    },
  },
};
</script>

<style lang="scss">
.inventory-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}
.v-list-item__title {
  font-size: 14px !important;
}
.stickyCell {
  position: sticky;
  right: 0;
  background: white;
  border-left: thin solid rgba(42, 54, 59, 0.12);
  box-shadow: 0px 5px 5px -3px rgb(42 54 59 / 16%), 0px 8px 10px 1px rgb(42 54 59 / 10%),
    0px 3px 14px 2px rgb(42 54 59 / 8%);
}

th.stickyCell {
  background: #f2f3f3;
}

.fullSize {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.cellHeight {
  height: 80px !important;
}
.scrolling {
  cursor: grabbing;
}
</style>
