import Api from "../../Shared/services/api";

const baseUrl = "equipments";
export default {
  update(Equipments) {
    return Api().put(baseUrl + `/BulkUpdate`, {
      Equipments,
    });
  },
};
